import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { socials } from "../config/socials";
import Icon from "../components/icons/Icon";

interface HeroProps {}

const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
`;

const Title = styled.h1`
  text-transform: uppercase;
  letter-spacing: 10px;
  color: var(--carribean-green);
`;

const SubTitle = styled.h2`
  color: var(--carribean-green-darker);
  text-transform: lowercase;
  font-family: (--font-mono);
  font-weight: 200;
  font-size: 16px;
`;

const StyledSocials = styled.div`
  margin: 25px 0;
  a {
    display: inline-block;
    padding: 0;
    &:not(:first-child) {
      margin-left: 25px;
    }
  }
  svg {
    height: 30px;
    width: 30px;
  }
`;

const StyledAddresses = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledAddress = styled.div`
  color: var(--carribean-green-darker);
  font-size: 10px;
  strong {
    color: var(--carribean-green);
  }
`;

const Hero = ({}: HeroProps) => {
  const resources = {
    cs: "Již brzy",
    pl: "Już wkrótce",
    en: "Coming soon",
  };

  const addresses = {
    BTC: "bc1qudjwyfl9h5a9tjyzu4js8r2dj7d567gc7qt9gu",
    ETH: "0x1E43028205171EB1103079f956CfBC1F03c07Ea2",
    LTC: "LXaFn4maKN5HxkssHKV3kuEQSCQKCYVj74",
  };

  const [title, setTitle] = useState(null);
  const [locale, setLocale] = useState(null);

  useEffect(() => {
    const { hostname } = window.location;
    setTitle(hostname.includes("krypto") ? "Kryptocentrum" : "Cryptocentrum");
    setLocale(hostname.includes(".pl") ? "pl" : "cs");
  }, []);

  if (!title || !locale) return null;

  return (
    <Section>
      <Title>{title}</Title>
      <SubTitle>
        {resources.en} | {resources[locale]}
      </SubTitle>
      <StyledSocials>
        {socials.map(item => (
          <a
            key={item.name}
            href={item.url}
            title={item.name}
            aria-label={item.name}
            target="_blank"
            rel="noreferrer"
          >
            <Icon name={item.name} />
          </a>
        ))}
      </StyledSocials>
      <StyledAddresses>
        {Object.entries(addresses).map(([currency, address]) => (
          <StyledAddress key={currency}>
            <strong>{currency}:</strong> {address}
          </StyledAddress>
        ))}
      </StyledAddresses>
    </Section>
  );
};

export default Hero;
