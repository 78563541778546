import React, { ReactElement } from "react";
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedInIcon,
  GitHubIcon,
} from "components/icons/index";
import { IconType } from "types";

interface IconProps {
  name: IconType;
}

export const Icon = ({ name }: IconProps): ReactElement => {
  const icons = {
    Facebook: <FacebookIcon />,
    Instagram: <InstagramIcon />,
    Twitter: <TwitterIcon />,
    LinkedIn: <LinkedInIcon />,
    GitHub: <GitHubIcon />,
  };

  return icons[name];
};

export default Icon;
